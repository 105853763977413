.my-atropos {
    width: 320px;
    height: 160px;

}
#dashFooter{
    background-color: unset !important;
}

.button10{
    background-color: black !important;
    color: #FFF !important;
    border: 2px solid black !important;
}